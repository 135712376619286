import Header from '../header/Header';


function HealthProviderHome() {
    return (
        <div className="App">
            <Header/>
            <h2>Home Page for Health Provider</h2>
        </div>
    )
}


export default HealthProviderHome;